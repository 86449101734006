import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'gatsby'
import * as classes from './Projects.module.scss'
import cn from 'classnames'
import Container from '../../ui/Container/Container'
import BreadCrumbs from '../../ui/BreadCrumbs/BreadCrumbs'
import IconRight from '../../icons/IconRight'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { getPhotoSize } from '../../../utils/getPhotoSize'
import { deleteHtmlTag } from '../../../utils/deleteHtmlTag'
import { FormContext } from '../../../context'
import splitRow from '../../../utils/splitRow'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { startAnimationScroll } from '../../../utils/animations'
import splitTitleV2 from '../../../utils/splitTitleV2'

gsap.registerPlugin(ScrollTrigger)

function Projects({ crumbs, data, lang, textLocation }) {
    const { animationStart } = useContext(FormContext)

    const windowSize = useWindowSize()
    const imageSize = getPhotoSize(windowSize.width)

    const title = useMemo(() => deleteHtmlTag(data.title.value, 'p'), [])
    const text = useMemo(() => deleteHtmlTag(data.text.value, 'p'), [])
    const location = useMemo(() => deleteHtmlTag(textLocation, 'p'), [])

    const getItemLink = (href) => {
        if (lang === 'hy') {
            return href
        } else {
            return `/en${href}`
        }
    }
    const [titlePrint, setTitlePrint] = useState(title)

    let newArr = []

    if (data.items.nodes.length) {
        newArr = data.items.nodes.map((item) => {
            return {
                title: item.title,
                desc: deleteHtmlTag(item.previewText, 'p'),
                urlCode: item.urlCode,
                location: item.location,
                photo: {
                    1440: item.previewPictureDesktop.url,
                    768: item.previewPictureDesktop.url,
                    360: item.previewPictureDesktop.url
                }
            }
        })
    }

    const classAnimationTitle = 'anime-title-projects-brief'
    const classAnimationText = 'anime-text-projects-brief'

    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
            splitTitleV2(classAnimationTitle)
        }

        const observer = startAnimationScroll([
            { type: 'text', className: classAnimationText },
            { type: 'title', className: classAnimationTitle }
        ])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    useEffect(() => {
        const timer = setTimeout(() => {
            const elems = document.querySelectorAll('.projects-item-footer')

            elems.forEach((item, index) => {
                gsap.to(item, {
                    duration: 1.4,
                    opacity: 1,
                    ease: 'cubicBezier(0.25, 0.1, 0.25, 1)',
                    scrollTrigger: {
                        trigger: item,
                        start: 'top 80%'
                    }
                })
            })
        }, 1500)

        return () => clearTimeout(timer)
    }, [])

    return (
        <section className={cn(classes.Section, { [classes.LangHY]: lang === 'hy' })}>
            <Container>
                <BreadCrumbs list={crumbs} black />

                {titlePrint || text ? (
                    <div className={classes.Header}>
                        {titlePrint ? (
                            <h2 className={`${classes.Title} ${classAnimationTitle}`} dangerouslySetInnerHTML={{ __html: titlePrint }} />
                        ) : null}

                        {text ? <p className={`${classes.Desc} ${classAnimationText}`} dangerouslySetInnerHTML={{ __html: text }} /> : null}
                    </div>
                ) : null}

                {data.items.nodes.length ? (
                    <div className={classes.Items}>
                        {newArr.map((item, index) => (
                            <div className={cn(classes.Item, { [classes.LangHY]: lang === 'hy' })} key={index}>
                                <Link to={getItemLink(`/projects/${item.urlCode}/`)}>
                                    {item.photo && (item.photo[imageSize] || item.photo[1440]) ? (
                                        <div
                                            className={classes.ItemImage}
                                            style={{
                                                backgroundImage: `url(${item.photo[imageSize] ? item.photo[imageSize] : item.photo[1440]})`
                                            }}
                                        />
                                    ) : null}

                                    <div className={classes.ItemContent}>
                                        {item.title && (
                                            <h3
                                                className={`${classes.ItemTitle} ${classAnimationTitle} projects-item-title`}
                                                dangerouslySetInnerHTML={{ __html: item.title }}
                                            />
                                        )}

                                        {item.desc && (
                                            <p
                                                className={`${classes.ItemDesc} ${classAnimationText}`}
                                                dangerouslySetInnerHTML={{ __html: item.desc }}
                                            />
                                        )}

                                        <div className={`${classes.ItemFooter} projects-item-footer`}>
                                            {item.location && (
                                                <p className={classes.ItemLocation}>
                                                    {location ? location + ': ' : null}
                                                    <span dangerouslySetInnerHTML={{ __html: item.location }} />
                                                </p>
                                            )}

                                            <Link to={getItemLink(`/projects/${item.urlCode}/`)}>
                                                <div className={classes.ItemDetails}>
                                                    <div className={classes.IconRightWrap}>
                                                        <IconRight />
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : null}
            </Container>
        </section>
    )
}

export default Projects
