// extracted by mini-css-extract-plugin
export var Section = "Projects-module--Section--gW20z";
export var LangHY = "Projects-module--LangHY--kADDw";
export var ItemDesc = "Projects-module--ItemDesc--DmPN1";
export var ItemLocation = "Projects-module--ItemLocation--dgpJ8";
export var Header = "Projects-module--Header--PYygE";
export var Title = "Projects-module--Title--AVVbP";
export var Desc = "Projects-module--Desc--e20gz";
export var Item = "Projects-module--Item--iqnnG";
export var ItemImage = "Projects-module--ItemImage--U+RKQ";
export var ItemContent = "Projects-module--ItemContent--Wzxso";
export var ItemTitle = "Projects-module--ItemTitle--5tQvZ";
export var ItemFooter = "Projects-module--ItemFooter--jDhMp";
export var ItemDetails = "Projects-module--ItemDetails--7PUP0";
export var IconRightWrap = "Projects-module--IconRightWrap--HipHr";
export var arrowLeft = "Projects-module--arrowLeft--0vrvG";
export var arrowRight = "Projects-module--arrowRight--FxC+9";